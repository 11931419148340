<template>
  <div class="landing">
    <div class="landing-container">
      <div class="landing-content">
        <div class="landing-image">
          <img src="~assets/images/graphics/not-found.png" />
        </div>
        <div class="landing-text">
          <h1>Whoops</h1>
          <h2>Even the things we love break sometimes.</h2>
          <p>
            Thanks for your patience while we put the pieces back together.
            Contact support-qontak@mekari.com for more information. Go back to
            <NuxtLink class="error-link" to="/">Qontak.com</NuxtLink>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorsPage500',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: 'Whoops!!! Even the things we love break sometimes.',
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0',
        },
      ],
    }
  },
}
</script>

<style scoped>
.landing {
  display: flex;
  align-items: center;
  min-height: 100vh;
  background: #0274f5;
  color: #fff;
}

.landing-container {
  max-width: 960px;
  margin: 0 auto;
}

.landing-content {
  display: flex;
  align-items: center;
}

.landing-image {
  width: 50%;
}

.landing-image img {
  max-width: 100%;
}

.landing-text {
  flex: 1;
  padding-left: 30px;
  font-size: 18px;
  line-height: 1.25;
}

.landing-text h1 {
  margin-bottom: 20px;
  font-family: Anton, sans-serif;
  font-size: 100px;
}

.landing-text h2 {
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 500;
}

.landing-text p {
  margin: 0 0 15px;
}

.landing-text a {
  color: #fff;
  font-weight: bold;
}

@media (width <= 992px) {
  .landing-container {
    max-width: 100%;
    padding: 20px 15px;
  }

  .landing-content {
    flex-direction: column;
    justify-content: center;
  }

  .landing-image {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .landing-text {
    padding-left: 0;
    text-align: center;
  }
}

@media (width <= 767px) {
  .landing-content {
    padding: 20px;
  }

  .landing-text h1 {
    font-size: 70px;
  }

  .landing-image {
    margin-bottom: 0;
  }

  .landing-text h2 {
    font-size: 26px;
  }

  .landing-text {
    font-size: 16px;
  }
}
</style>
